import Footer from "../components/Footer";
import MarkdownViewer from "../components/MarkdownViewer";


const AppPrivacy = () => {
    // need to get public link to the repo or for private links need to get Personal Access Token (https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens)
    const privacyMarkdown = `https://raw.githubusercontent.com/HubbleNetwork/legal-pages/refs/heads/master/app-privacy-policy.md?cache-bust=${new Date().getTime()}`; // Replace with your actual URL

    return (<>
            <section id="TERMS" className="terms">
                <div className="terms__container">
                    <MarkdownViewer url={privacyMarkdown}/>
                </div>
            </section>
            <Footer/>
        </>
    );
};
export default AppPrivacy;
