import Footer from "../components/Footer";
import React, {useEffect} from 'react';

const JobBoard = () => {
    useEffect(() => {
        const existingScript = document.querySelector(
            'script[src="https://boards.greenhouse.io/embed/job_board/js?for=hubblenetwork"]'
        );
        if (existingScript) {
            existingScript.remove();
        }

        const script = document.createElement('script');
        script.src = 'https://boards.greenhouse.io/embed/job_board/js?for=hubblenetwork';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    return (
        <>
            <div className="nav-shadow-gradient"></div>
            <section className="careers">
                <div className="careers_container">
                    <div id="grnhse_app" >Loading jobs...</div>
                </div>
                <Footer/>
            </section>
        </>
    );
};
export default JobBoard;
