import Footer from "../components/Footer";
import MarkdownViewer from "../components/MarkdownViewer";

const Terms = () => {
    // need to get public link to the repo or for private links need to get Personal Access Token (https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens)
    const termsMarkdown = `https://raw.githubusercontent.com/HubbleNetwork/legal-pages/refs/heads/master/terms-of-service.md?cache-bust=${new Date().getTime()}`;

    return (
        <>
            <section id="TERMS" className="terms">
                <div className="terms__container">
                    <MarkdownViewer url={termsMarkdown}/>
                </div>
            </section>
            <Footer/>
        </>
    );
};
export default Terms;
