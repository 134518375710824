import React, { useEffect, useState } from 'react';
import { fetchMarkdown } from '../services/fetchMarkdown';
import ReactMarkdown from "react-markdown";

const MarkdownViewer = ({ url }) => {
    const [content, setContent] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getMarkdown = async () => {
            try {
                const data = await fetchMarkdown(url);
                setContent(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        getMarkdown();
    }, [url]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <ReactMarkdown>{content}</ReactMarkdown>
        </div>
    );
};

export default MarkdownViewer;