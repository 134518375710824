import "./App.scss";
import {Routes, Route, HashRouter} from "react-router-dom";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//pages
import Home from "./pages/Home";
import Navigation from "./components/Navigation";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import AppSupport from "./pages/AppSupport";
import Subprocessors from "./pages/Subprocessors";
import AppPrivacy from "./pages/AppPrivacy";
import JobBoard from "./pages/JobBoard";

function App() {
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollTrigger);

  return (
    <div className="App">
      <HashRouter>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/careers" element={<JobBoard />} />
          <Route path="/app-privacy-policy" element={<AppPrivacy/>} />
          <Route path="/demo-app-support" element={<AppSupport />} />
          <Route path="/subprocessors" element={<Subprocessors />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
