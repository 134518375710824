import React, { useCallback, useState } from "react";
import {useNavigate, Link} from "react-router-dom";
import { gsap } from "gsap";

import logo from "../assets/images/navbar/Logo.svg";
import menu from "../assets/images/navbar/Menu.svg";
import close from "../assets/images/navbar/Close.svg";
import Button from "./Button";
import "./Navigation.css";
import styled, { css } from "styled-components";
import { useForm } from "@formspree/react";
import { FORMSPREE } from "../utils";

const Nav = styled.nav`
  ${() => css`
    .navigation .container a {
    }
  `}
`;

const Navigation = () => {
  const navigate = useNavigate();
  const [state, handleSubmit] = useForm(FORMSPREE);

  const [isOpen, setIsOpen] = useState(false);

  const scrollTo = (element) => {
    gsap.to(window, { duration: 2, scrollTo: element })
  }

  const handleChangeOpenstate = useCallback(
    () => setIsOpen((prevState) => !prevState),
    []
  );
  return (
    <Nav>
      <div className="navigation">
        <div className="container">
          <Link to={"/"} onClick={() => scrollTo("#trigger-top-banner")}>
            <img alt="-logo" id="logo" src={logo}/>
          </Link>
          <div className="menu-items">
            <Link to={"/"} onClick={() => scrollTo("#trigger-top-banner")}>About</Link>
            <Link to={"/"} onClick={() => scrollTo("#use-cases")}>Use Cases</Link>
            <a href="/blog/">Blog</a>
            <a href="/jobs/">Careers</a>
          </div>

          <img
              id="menu"
              alt="-mobile-menu"
              className="mobile-menu"
              src={isOpen ? close : menu}
              onClick={handleChangeOpenstate}
          />
        </div>
      </div>
      {isOpen && (
          <div className="mobile-navigation">
            <div className="mobile-container">
              <Link to={"/"} onClick={() => scrollTo("#trigger-top-banner")}>About</Link>
              <Link to={"/"} onClick={() => scrollTo("#use-cases")}>Use Cases</Link>
              <a href="/blog/">Blog</a>
              <a href="/jobs/">Careers</a>

              {!state?.succeeded ? (
                  <form onSubmit={handleSubmit}>
                    <div className="contact">
                      <input
                          required
                          id="email"
                          type="email"
                          name="email"
                          placeholder="Enter your email to stay informed"
                      />
                      <Button>Sign Up</Button>
                    </div>
                  </form>
              ) : (
                  <div className="nav-thanks">
                    <h1>Thank you for your interest.</h1>
                    <p>
                      Your submission has been received. We will be contacting you
                      via email for next steps.
                    </p>
                  </div>
              )}
              <div className="mobile-footer">
                <label>Hubble Network © 2024 </label> |{" "}
                <label onClick={() => navigate("/privacy-policy")}>Privacy Policy </label> | <label
                  onClick={() => navigate("/terms")}>Terms</label>
              </div>
            </div>
            <div className="shadow" />
        </div>
      )}
    </Nav>
  );
};
export default Navigation;
